import React, { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import ModalContent from 'components/base/modal/modalcontent';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormItem, FormSection } from 'components/base/form/FormItem';
import { postApiFormData } from 'util/backendapi/fetch';
import { useIsMounted } from 'util/hooks';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { FileField } from 'components/base/form/file-field/FileField';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import './PreviewMediaModal.scss';
import { getExpectedFields } from 'util/backendapi/error';

export interface UploadReadingMediaModalProps {
  readingId: number;
  observationPointCode: string;
  mediaContentType: string;
  hideModal: () => void;
  onAfterSubmit: () => void;
}

type FormValues = {
  new_file: File | null;
  new_file_name: string;
};

export function UploadReadingMediaModal(props: UploadReadingMediaModalProps) {
  const {
    readingId,
    observationPointCode,
    mediaContentType,
    hideModal,
    onAfterSubmit,
  } = props;
  const isMounted = useIsMounted();

  const initialValues: FormValues = {
    new_file: null,
    new_file_name: '',
  };

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      if (!values.new_file) return;

      const formData = new FormData();
      formData.append('file', values.new_file!, values.new_file!.name);

      try {
        formData.append(
          'values',
          JSON.stringify({
            content_type: mediaContentType,
            readings: [readingId],
          })
        );
        await postApiFormData(`/media/`, formData);
        if (!isMounted()) {
          return;
        }
        onAfterSubmit();
        hideModal();
      } catch (e) {
        if (!isMounted()) {
          return;
        }
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }
    },
    [onAfterSubmit, hideModal, isMounted, mediaContentType, readingId]
  );

  return (
    <ModalContent
      className="preview-media-modal"
      header={<Trans>Preview media</Trans>}
    >
      <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection>
              <FormItem label={<Trans>Upload a new file</Trans>}>
                <FileField
                  name="new_file_name"
                  fileFieldName="new_file"
                  disabled={formik.isSubmitting}
                />
                <FieldError name="new_file_name" />
              </FormItem>
              <FormChangeEffect
                onChange={(prevFormikState) => {
                  if (
                    prevFormikState.values.new_file_name !==
                    formik.values.new_file_name
                  ) {
                    formik.submitForm();
                  }
                }}
              />
              <FormItem label={<Trans>Observation point</Trans>}>
                {observationPointCode}
              </FormItem>
            </FormSection>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
